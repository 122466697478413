import "./App.css";
import { useEffect, useLayoutEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import "./admin/assets/custom/css/style.bundle.css";
import "./admin/assets/custom/plugins/global/plugins.bundle.css";
import "./i18n";
import { Splash } from "./admin/components/Loader";
import AdminRoutes from "./admin/AdminRoutes";
import ClientRoutes from "./client/ClientRoutes";
import Login from "./Login";
import FiveLastPrint from "./admin/components/PrintLastDetails";

function App() {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("i18nextLng") == null) {
      localStorage.setItem("i18nextLng", "fr", {
        expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
      });
    } else
      localStorage.setItem("i18nextLng", localStorage.getItem("i18nextLng"), {
        expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
      });

    setTimeout(() => setShowSplash(false), 1200);
  }, []);

  return (
    <>
      <Router>
        {showSplash && window.location.pathname !== "/" && <Splash />}
        <Routes>
          <Route exact path="/Login" element={<Login />} />
          <Route exact path="/" element={<Login />} />
          <Route exact path="/aa" element={<FiveLastPrint />} />
        </Routes>
        <AdminRoutes />
        <ClientRoutes />
      </Router>
    </>
  );
}

export default App;
