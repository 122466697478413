import { useTranslation } from "react-i18next";
import foot from "../assets/img/foot.png";
import React, { useEffect, useRef } from "react";
// import { Line } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from "moment";

// Enregistrement des composants Chart.js nécessaires
// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const FiveLastPrint = ({ allData, fiveLastDetails, sampleId, myRef }) => {

    const chartRef1 = useRef(null);
    const chartRef2 = useRef(null);


    const groupedData = Object.values(
        fiveLastDetails.reduce((acc, item) => {
            if (!acc[item.reportid]) {
                acc[item.reportid] = [];
            }
            acc[item.reportid].push(item);
            return acc;
        }, {})
    );



    useEffect(() => {
        const handleBeforePrint = () => {
            if (chartRef1.current) {
                chartRef1.current.chartInstance.update();
            }
            if (chartRef2.current) {
                chartRef2.current.chartInstance.update();
            }
        };

        window.addEventListener("beforeprint", handleBeforePrint);
        return () => {
            window.removeEventListener("beforeprint", handleBeforePrint);
        };
    }, []);

    const ironData = groupedData.map((x, index) => { return { label: index + 1, Iron: x.filter((y)=> y.analytename.slice(0, 2) === "Fe")[0].ConcInCalibUnits && x.filter((y)=> y.analytename.slice(0, 2) === "Fe")[0].ConcInCalibUnits > 0 ? x.filter((y)=> y.analytename.slice(0, 2) === "Fe")[0].ConcInCalibUnits+"" : '0' } })
    
    const data100 = allData.map((x, index) => { return { label: index + 1, viscosity100c: x.viscosity100c } })
    


    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                enabled: false,
            },
            datalabels: {
                display: true,
                align: "top", // Position au-dessus des points
                color: "black", // Couleur du texte
                font: {
                    size: 14, // Taille du texte
                    weight: "bold",
                },
                formatter: (value) => value.toFixed(1) + "°C", // Ajoute "°C" après chaque valeur
            },
        },
        scales: {
            x: {
                ticks: {
                    padding: 10, // Espacement entre l'axe et les labels
                },
                offset: true, // Décale légèrement les labels sur l'axe X
            },
            y: {
                min: 13.5,
                max: 16,
            },
        },
    };



    return <div ref={myRef} className="mt-10 mt-md-0 col-xl-8 px-16 py-12 bg-white">
        <div style={{height:"1330px"}}>
            <div className="w-100 text-center p-2 fs-bold" style={{ backgroundColor: "#FFFF00", fontWeight:"900" }}>Previous Sample history</div>
            <div className="d-flex justify-content-center col-xl-12  ">
                <div className="d-flex justify-content-center col-xl-8 mt-2 ">
                    <table className="w-100" style={{ border: '1px', borderColor: 'red' }}>
                        <thead>
                            <tr>
                                <th scope="col" className="text-left">
                                    Sample
                                    <a
                                        data-toggle="modal"
                                        data-target=""
                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Sample ID
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#ticketsubjectModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Sample Date
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#ticketdescModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Process Date
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#ticketdescModal"

                                    ></a>
                                </th>

                                <th scope="col" className="text-left">
                                    Equip. Hours
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Fluid Hours
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Oil Brand & Grade
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left"></th>
                            </tr>
                        </thead>
                        <tbody id="resultdata">
                            {
                                allData.map((x, index) => {
                                    return <tr className="border border-1">
                                        <td id="tb-assigned-to" className="text-left">
                                            {index + 1}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {sampleId}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.sampleDate ? moment(x.sampleDate).format('DD-MM-YYYY') : "-"}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.createddate ? moment(x.createddate).format('DD-MM-YYYY') : "-"}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.equipmentHours}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.fluidHours}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.brand}
                                        </td>
                                    </tr>
                                })
                            }


                        </tbody>
                    </table>
                </div>
            </div>

            <div className="w-100 text-center mt-10 p-2" style={{ backgroundColor: "#FFFF00", fontWeight:"900" }}>Previous Action history</div>
            <div className="d-flex justify-content-center col-xl-12  p-5 fs-12">
                <div>
                    {
                        allData.map(x => {
                            return (
                                <div className="d-flex border mt-1">
                                    <div className={`p-1 ${ x.action === "Action required" ? ' bg-red ' : x.action === "Monitor component" ? " bg-warning " : "bg-success3"}  text-white text-uppercase mt-1 text-nowrap`} style={{width:"175px" , height:"23px"}}>{x.action !== "" ? x.action : "No action selected "} </div>
                                    <div className="p-1 text-uppercase fs-10 px-2" style={{width:"650px"}}>{x.comment !== "" ? x.comment : ""} </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>

            <div className="w-100 text-center mt-10 p-2" style={{ backgroundColor: "#FFFF00", fontWeight:"900" }}> Wear Metals And Contaminants</div>
            <div className="d-flex justify-content-center col-xl-12  ">
                <div className="d-flex justify-content-center col-xl-12 mt-2 ">
                    <table className="w-100" style={{ border: '1px', borderColor: 'red' }}>
                        <thead>
                            <tr>

                                <th scope="col" className="text-left">
                                    Sample
                                    <a
                                        data-toggle="modal"
                                        data-target=""
                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Silicon
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#ticketsubjectModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Tin
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#ticketdescModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Chromium
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#ticketdescModal"

                                    ></a>
                                </th>

                                <th scope="col" className="text-left">
                                    Lead
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Nickel
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Iron
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Aluminium
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Copper
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Sodium
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Potassium
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                            </tr>
                        </thead>
                        <tbody id="resultdata">
                            {
                                groupedData ?
                                    groupedData.map((x, index) => {

                                        return <tr className="border border-1">
                                            <td id="tb-assigned-to" className="text-left">
                                                {index +1}
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Si")[0].ConcInCalibUnits) < 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Si")[0].ConcInCalibUnits).toFixed(2))
                                                }
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Sn")[0].ConcInCalibUnits) < 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Sn")[0].ConcInCalibUnits).toFixed(2))
                                                }
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Cr")[0].ConcInCalibUnits) < 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Cr")[0].ConcInCalibUnits).toFixed(2))
                                                }
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Pb")[0].ConcInCalibUnits) <= 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Pb")[0].ConcInCalibUnits).toFixed(2))
                                                }
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Ni")[0].ConcInCalibUnits) <= 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Ni")[0].ConcInCalibUnits).toFixed(2))
                                                }
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Fe")[0].ConcInCalibUnits) <= 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Fe")[0].ConcInCalibUnits).toFixed(2))
                                                }
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Al")[0].ConcInCalibUnits) <= 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Al")[0].ConcInCalibUnits).toFixed(2))
                                                }
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Cu")[0].ConcInCalibUnits) <= 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Cu")[0].ConcInCalibUnits).toFixed(2))

                                                }
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Na")[0].ConcInCalibUnits) <= 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Na")[0].ConcInCalibUnits).toFixed(2))

                                                }
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 1) === "K")[0].ConcInCalibUnits) <= 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 1) === "K")[0].ConcInCalibUnits).toFixed(2))

                                                }
                                            </td>

                                        </tr>
                                    }) : null
                            }


                        </tbody>
                    </table>
                </div>
            </div>

            <div className="w-100 text-center mt-10 p-2" style={{ backgroundColor: "#FFFF00", fontWeight:"900" }}> Additives</div>
            <div className="d-flex justify-content-center col-xl-12  ">
                <div className="d-flex justify-content-center col-xl-7 mt-2 ">
                    <table className="w-100" style={{ border: '1px', borderColor: 'red' }}>
                        <thead>
                            <tr>

                                <th scope="col" className="text-left">
                                    Sample
                                    <a
                                        data-toggle="modal"
                                        data-target=""
                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Molybdenum
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#ticketsubjectModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Zinc
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#ticketdescModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Phosphorus
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#ticketdescModal"

                                    ></a>
                                </th>

                                <th scope="col" className="text-left">
                                    Calcium
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Magnesium
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>

                                <th scope="col" className="text-left"></th>
                            </tr>
                        </thead>
                        <tbody id="resultdata">

                            {
                                groupedData ?
                                    groupedData.map((x, index) => {
                                        return <tr className="border border-1">
                                            <td id="tb-assigned-to" className="text-left">
                                                {index + 1}
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Mo")[0].ConcInCalibUnits) <= 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Mo")[0].ConcInCalibUnits).toFixed(2))
                                                }
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Zn")[0].ConcInCalibUnits) <= 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Zn")[0].ConcInCalibUnits).toFixed(2))
                                                }
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 1) === "P")[0].ConcInCalibUnits) <= 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 1) === "P")[0].ConcInCalibUnits).toFixed(2))
                                                }
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Ca")[0].ConcInCalibUnits) <= 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Ca")[0].ConcInCalibUnits).toFixed(2))
                                                }
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {
                                                    parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Mg")[0].ConcInCalibUnits) <= 0 ? "0" : Math.round(parseFloat(x.filter(str => str.analytename.slice(0, 2) === "Mg")[0].ConcInCalibUnits).toFixed(2))
                                                }
                                            </td>
                                        </tr>
                                    }
                                    ) : null
                            }


                        </tbody>
                    </table>
                </div>
            </div>

            <div className="w-100 text-center mt-10 p-2" style={{ backgroundColor: "#FFFF00", fontWeight:"900" }}> Viscosity And Iron graphs</div>
            <div className="d-flex justify-content-center col-xl-12  ">
                <div className="col-xl-5  d-flex justify-content-center mt-6">
                    {/* <Line ref={chartRef1} data={data100} options={options} /> */}
                    <ResponsiveContainer width="100%" height={200}>
                        <LineChart data={data100}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="label" />
                            <YAxis domain={[0, 70]} />
                            <Tooltip />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="viscosity100c"
                                stroke="blue"
                                strokeWidth={2}
                                dot={{ r: 2, fill: "blue" }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                <div className="col-xl-5  d-flex justify-content-center mt-6">
                    {/* <Line ref={chartRef2} data={data40} options={options} /> */}
                    <ResponsiveContainer width="100%" height={200}>
                        <LineChart data={ironData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="label" />
                            <YAxis domain={[0, 10]} />
                            <Tooltip />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="Iron"
                                stroke="blue"
                                strokeWidth={2}
                                dot={{ r: 2, fill: "blue" }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>

            <div className="w-100 text-center mt-10 p-2" style={{ backgroundColor: "#FFFF00", fontWeight:"900" }}> Oil Condition/Particle Count (ct/ml)</div>
            <div className="d-flex justify-content-center col-xl-12  ">
                <div className="d-flex justify-content-center col-xl-12 mt-2 ">
                    <table className="w-100" style={{ border: '1px', borderColor: 'red' }}>
                        <thead>
                            <tr>

                                <th scope="col" className="text-left">
                                    Sample
                                    <a
                                        data-toggle="modal"
                                        data-target=""
                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    ISO
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#ticketsubjectModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Soot
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#ticketdescModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Oxidation
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#ticketdescModal"

                                    ></a>
                                </th>

                                <th scope="col" className="text-left">
                                    Nitration
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Sulfation
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Water
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Antifreeze
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    Fuel
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    PFC
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                                <th scope="col" className="text-left">
                                    TBN
                                    <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#priorityModal"

                                    ></a>
                                </th>
                            </tr>
                        </thead>
                        <tbody id="resultdata">

                            {
                                allData.map((x, index) => {
                                    return <tr className="border border-1">
                                        <td id="tb-assigned-to" className="text-left">
                                            {index + 1}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.iso}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.soot}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.oxidation}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.nitration}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.sulfaction}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.wation}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.antifreeze}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.fuel}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.PFC}
                                        </td>
                                        <td id="tb-assigned-to" className="text-left">
                                            {x.tbn}
                                        </td>

                                    </tr>
                                })
                            }


                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="fixed mt-10 bottom"><img
            alt="Logo"
            src={foot}
            className="logo-default max-h-100px w-100"
        />
        </div>

    </div>
}

export default FiveLastPrint